import request from "../utils/request";

/**
 * @typedef {Object} LoginForm
 * @property {String} username_email
 * @property {String} password
 * @property {String} method
 */

/**
 *
 * @param {LoginForm} data
 * @returns
 */

export function login(data) {
  let payload = {
      password: data.password,
      username: undefined,
      email: undefined,
    },
    url;

  if (data.method === "username") {
    payload.username = data.username_email;
    url = "/accounts/login/";
  } else {
    payload.email = data.username_email;
    url = "/accounts/login/email/";
  }
  return request({
    url,
    method: "post",
    data: payload,
  });
}

export function getInfo() {}

/**
 *@todo Implement function
 */
export function logout() {
  return new Promise((resolve) => {
    setTimeout(() => resolve(true), 1);
  });
  // return request({
  //   url: "/auth/logout",
  //   method: "post",
  // });
}

/**
 *
 * @param {String} email
 * @returns
 */
export function forgotPassword(email) {
  return request({
    url: "/accounts/users/forgot_password/",
    method: "post",
    data: {
      email,
    },
  });
}

/**
 *
 * @param {Object} data
 * @returns
 */
export function resetPassword(data) {
  return request({
    url: "/accounts/users/reset_password/",
    method: "post",
    data,
  });
}
