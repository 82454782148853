import { Message } from "element-ui";
import axios from "axios";
import { getToken } from "../utils/auth";
import router from "../router";
import store from "../store";

// Create axios instance
const service = axios.create({
  // baseURL: "http://208.87.128.190:2354/api",
  baseURL: "https://api.dmi.fmohconnect.gov.ng/api",
  timeout: 50000,
});

// Request intercepter
service.interceptors.request.use(
  async (config) => {
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = "Basic " + token;
    }
    config.onUploadProgress = async function (event) {
      const progress = parseInt(Math.round((event.loaded / event.total) * 100));
      0 && console.log(progress);
    };

    return config;
  },
  async (error) => {
    Promise.reject(error);
  }
);

// Response pre-processing
service.interceptors.response.use(
  async (response) => {
    return response.data;
  },
  async (error) => {
    const statusCode = error?.response?.status || 500;
    let msg = error.message;
    // For UnAuthorized response logout user and persits any  transient data
    if (statusCode == 401) {
      await store.dispatch("user/resetToken");
      router.push(`/login?redirect=${router.currentRoute.path}`);
    } else if (statusCode == 400) {
      if (error?.response?.data && error.response.data.detail) msg = error.response.data.detail;
      // Allow calling method to handle failure. If the system does any form of tracking
      // or anallytics  collection response can handled here
    } else if (statusCode == 500) {
      Message({
        message: msg,
        type: "error",
        duration: 3 * 1000,
        showClose: true,
      });
    }
    // Reject
    return Promise.reject(error);
  }
);

export default service;
