import Resource from "./resource";
import request from "../utils/request";

/**
 *Handles uploading and processing of files
 */
class RecordResource extends Resource {
  constructor() {
    super("records");
  }
  getDataRecords() {
    return request({
      url: `/${this.uri}/data/data_records/`,
      method: "get",
    });
  }
}

export { RecordResource as default };
