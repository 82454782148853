import AppMain from "../../components/layout/AppMain";

const DataManagementRoutes = {
  path: "/data",
  component: AppMain,
  redirect: "/data/upload",
  name: "DataManagement",
  meta: {
    title: "Data Entry",
    icon: "excel",
    permissions: ["view data"],
  },
  children: [
    {
      path: "saved",
      component: () => import("@/views/data/Saved"),
      name: "DataPreview",
      meta: { title: "Saved Data", permissions: [] },
    },
    //subNav
    {
      path: "upload",
      subNav: true,
      component: () => import("@/views/data/Create"),
      name: "DataUpload",
      meta: { title: "Upload Data" },
    },
    {
      path: "preview",
      hidden: true,
      subNav: true,
      component: () => import("@/views/data/PreviewData"),
      name: "PreviewData",
      meta: { title: "Preview Data" },
    },
    {
      path: "check-meta-data",
      hidden: true,
      subNav: true,
      component: () => import("@/views/data/MetaDataNormalization"),
      name: "DataCheckMetaData",
      meta: { title: "Normalize" },
    },
    {
      path: "duplicates",
      hidden: true,
      subNav: true,
      component: () => import("@/views/data/DuplicatesCheck"),
      name: "DataCheckDuplicate",
      meta: { title: "Duplicate Check" },
    },
    {
      path: "duplicates-db",
      hidden: true,
      subNav: true,
      component: () => import("@/views/data/DBDuplicatesCheck"),
      name: "DataCheckDuplicateDB",
      meta: { title: "Database Duplicate Check" },
    },
    {
      path: "complete",
      hidden: true,
      subNav: true,
      component: () => import("@/views/data/Complete"),
      name: "DataComplete",
      meta: { title: "Save & Update" },
    },
  ],
};

export default DataManagementRoutes;
