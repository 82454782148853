import AppMain from "../../components/layout/AppMain";

// eslint-disable-next-line no-unused-vars
const indicatorRoutes = [
  {
    path: "indicator/create",
    subNav: true,
    component: () => import("@/views/meta/indicators/Create"),
    name: "DS_Create",
    meta: { title: "Indicator Section", navTitle: "Add new indicator" },
  },
  {
    path: "indicator/preview",
    hidden: true,
    subNav: true,
    component: () => import("@/views/meta/indicators/Preview"),
    name: "DS_PreviewData",
    meta: { title: "Preview Indicator(s)" },
  },
  {
    path: "indicator/check-duplicates",
    hidden: true,
    subNav: true,
    component: () => import("@/views/meta/indicators/Blank"),
    name: "DS_CheckDuplicates",
    meta: { title: "Check for Duplicates" },
  },
  {
    path: "indicator/db-duplicates",
    hidden: true,
    subNav: true,
    component: () => import("@/views/meta/indicators/Blank"),
    name: "DS_CheckDuplicatesDB",
    meta: { title: "Check Duplicates in DB" },
  },
  {
    path: "indicator/save",
    hidden: true,
    subNav: true,
    component: () => import("@/views/meta/indicators/Blank"),
    name: "DS_Submit",
    meta: { title: "Review & Submit" },
  },
];

const MetaManagementRoutes = {
  path: "/meta",
  component: AppMain,
  redirect: "/meta/indicator/create",
  name: "MetaManagement",
  meta: {
    title: "Meta Data",
    icon: "tree-table",
    permissions: ["view data"],
  },
  children: [
    ...indicatorRoutes,
    {
      path: "saved",
      component: () => import("@/views/meta/indicators/Blank"),
      name: "SavedMetaData",
      meta: { title: "Saved meta-data" },
    },
  ],
};

export default MetaManagementRoutes;
