<template>
  <footer class="p-4">
    <div class="container-fluid fixed-bottom bg-secondary text-white p-2">
      <span class="p-1 m-4">Built by eHealth4everyone</span>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
