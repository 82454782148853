import AppMain from "../../components/layout/AppMain";

const UserManagementRoutes = {
  path: "/users",
  component: AppMain,
  redirect: "/users/list",
  name: "UserManagement",
  meta: {
    title: "Users",
    icon: "user",
    permissions: ["view data"],
  },
  children: [
    {
      path: "list",
      component: () => import("@/views/users/ListUsers"),
      name: "ListUsers",
      meta: { title: "View users", permissions: [] },
    },
    {
      path: "invite",
      component: () => import("@/views/users/InviteUser"),
      name: "InviteUser",
      meta: { title: "Invite user", permissions: [], role: [] },
    },
    // {
    //   path: "create",
    //   component: () => import("@/views/users/Create"),
    //   name: "CreateUser",
    //   meta: { title: "Create User", permissions: [] },
    // },
  ],
};

export default UserManagementRoutes;
