import "nprogress/nprogress.css"; // progress bar style

import { Message } from "element-ui";
import NProgress from "nprogress";
import getPageTitle from "./utils/get-page-title";
import { getToken } from "./utils/auth";
import router from "./router";
import store from "./store";

NProgress.configure({ showSpinner: false });

const whiteList = ["/login", "/auth-redirect", "/reset-password"];

router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start();

  // Pgae title
  document.title = getPageTitle(to.meta.navTitle || to.meta.title);

  // determine whether the user is logged in
  const isUserLogged = getToken();

  if (isUserLogged) {
    if (to.path === "/login") {
      // if is logged in and the route being navigated to is  the login route
      // redirect to the home page

      next({ path: "/" });
      NProgress.done();
    } else {
      if (store.getters.routesGenerated) {
        next();
      } else {
        try {
          // get user info
          // note: roles must be an array! such as: ['admin'] or ,['manager','editor']
          const { roles, permissions } = await store.dispatch("user/getInfo");

          // generate accessible routes  based on roles
          const accessRoutes = await store.dispatch("permission/generateRoutes", {
            roles,
            permissions,
          });

          router.addRoutes(accessRoutes);
          next({ ...to, replace: true });
        } catch (error) {
          // remove token and go to login page to re-login
          await store.dispatch("user/resetToken");
          store.dispatch("data/reset_state");
          store.dispatch("permission/reset_state");

          Message.error(error.message || "Error prcessing request");
          next(`/login?redirect=${to.path}`);
          NProgress.done();
        }
      }
    }
  } else {
    /* has no token*/
    // check if the route being havigated is whiteliste and deosnt needs Authorizatiom
    if (whiteList.indexOf(to.matched[0] ? to.matched[0].path : "") !== -1) {
      next();
    } else {
      // Redirect to login
      next(`/login?redirect=${to.path}`);
      NProgress.done();
    }
  }
});

// eslint-disable-next-line no-unused-vars
router.onError((error) => {
  console.error(error);
});

router.afterEach(() => {
  NProgress.done();
});
