<template>
  <b-navbar sticky>
    <div class="text-light bg-secondary container-fluid p-1 d-flex justify-content-between">
      <div class="d-flex align-items-center text-uppercase">
        <p v-b-toggle.sidebar-1 class="h2 mb-2">
          <b-icon icon="list"></b-icon>
        </p>

        <img
          :src="require('@/assets/img/FMOH-Logo.png')"
          style="max-height: 46px; border-right: 1px solid #dbdbdb; padding-right: 10px"
          class="d-inline-block align-top"
          alt="FMOH"
        />

        <span class="navbar-brand header__title h6 px-3">DATA MANAGEMENT INTERFACE</span>
      </div>
      <div class="d-flex">
        <span style="font-size: 2rem">
          <b-icon icon="bell-fill" style="color: white"> </b-icon>
        </span>
        <b-badge pill variant="danger"><sup>5</sup></b-badge>
        <div>
          <b-dropdown right variant="link" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <div v-if="user.avatar" class="avatar-container">
                <div class="avatar-wrapper">
                  <img :src="'https://i.pravatar.cc/128'" class="user-avatar" />
                  <span class="username">{{ user.username }}</span>
                  <i class="el-icon-caret-bottom" />
                </div>
              </div>
              <div v-else id="profileImage" class="rounded-circle mr-2">
                {{ user.username | profile_pic_alt(fullName) }}
              </div>
            </template>

            <b-dropdown-item href="#" @click="logout">
              <b-icon icon="power" aria-hidden="true"></b-icon> Logout
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div style="pa"></div>
      </div>
    </div>
    <SideBar class="sidebar-container" />
  </b-navbar>
</template>

<script>
import SideBar from "./Sidebar";
import { mapGetters } from "vuex";
import { profile_pic_alt } from "../../../filters";

export default {
  components: {
    SideBar,
  },
  filters: {
    profile_pic_alt,
  },
  computed: {
    ...mapGetters(["user"]),
    fullName() {
      return `${this.user.name.first} ${this.user.name.last}`;
    },
  },
  methods: {
    logout() {
      this.$store
        .dispatch("user/logout")
        .then(() => {
          this.$router.push("/login");
          this.$store.dispatch("data/reset_state");
          this.$store.dispatch("permission/reset_state");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
ul.dropdown-menu {
  margin-top: 10px;
  background-color: #e9ecef;
}

.avatar-container {
  margin-right: 30px;

  .avatar-wrapper {
    margin-top: 5px;
    position: relative;

    .user-avatar {
      cursor: pointer;
      width: 40px;
      height: 40px;
      border-radius: 50px;
    }
    .username {
      color: white;
      padding-left: 5px;
    }

    .el-icon-caret-bottom {
      cursor: pointer;
      position: absolute;
      right: -20px;
      top: 25px;
      font-size: 12px;
    }
  }
}
#profileImage {
  width: 50px;
  height: 50px;
  background: #e9ecef;
  font-size: 16px;
  color: #5b5b58;
  text-align: center;
  line-height: 52px;
}
</style>
