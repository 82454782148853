/* eslint-disable no-unused-vars */
import "./permission";
import "./settings";
import "./icons";
import "./styles/index.scss";
import "../scss/main.scss";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import App from "./views/App.vue";
import ElementUI from "element-ui";
import VeeValidate from "vee-validate";
import Vue from "vue";
import locale from "element-ui/lib/locale/lang/en";
import nProgress from "nprogress";
import router from "./router";
import store from "./store";

/**
 *
 */
Vue.use(BootstrapVue);
/**
 *
 */
Vue.use(IconsPlugin);
/**
 *
 */
Vue.use(ElementUI, { locale });

/**
 *
 */
Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: "veeFields",
  errorBagName: "veeErrors",
});

/**
 * interface Vue {
 *     $progress: nProgress
 *   }
 */
Vue.prototype.$progress = nProgress;

/**
 *
 */
Vue.config.productionTip = true;

/**
 *
 */

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
