import { SET_USERS } from "../mutation-types";
import userResource from "../../api/userResource";

const userState = () => ({
  users: [],
});

const mutations = {
  /**
   *
   * @param {*} state
   * @param {*} users
   */
  [SET_USERS]: (state, users) => {
    state.users = users;
  },
};

const actions = {
  loadusers({ commit }) {
    return new Promise((resolve, reject) => {
      userResource
        .list()
        .then((users) => {
          commit(SET_USERS, users);
          resolve();
        })
        .catch((error) => reject(error));
    });
  },
};

export default {
  namespaced: true,
  state: userState,
  mutations,
  actions,
};
