import AppMain from "../components/layout/AppMain";
import DataManagementRoutes from "./modules/data";
import MetaManagementRoutes from "./modules/meta";
// eslint-disable-next-line no-unused-vars
import RoleRoutes from "./modules/roles";
import UserManagementRoutes from "./modules/users";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    roles: ['admin', 'editor']   Visible for these roles only
    permissions: ['manage user'] Visible for these permissions only
    title: 'title'               the name show in sub-menu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
  }
**/

// Routes that require no permission to access
export const constantRoutes = [
  {
    path: "/redirect",
    component: AppMain,
    hidden: true,
    children: [
      {
        path: "/redirect/:path*",
        component: () => import("@/views/redirect/index"),
      },
    ],
  },
  {
    path: "/login",
    component: () => import("../views/auth/Login"),
    hidden: true,
    meta: { title: "Login" },
  },
  {
    path: "/reset-password",
    component: () => import("../views/auth/ForgotPassword"),
    hidden: true,
    meta: { title: "Reset Password" },
  },
  {
    path: "/auth-redirect",
    component: () => import("@/views/auth/AuthRedirect"),
    hidden: true,
  },
  {
    path: "/404",
    redirect: { name: "Page404" },
    component: () => import("@/views/error-page/404"),
    hidden: true,
  },
  {
    path: "/401",
    component: () => import("@/views/error-page/401"),
    hidden: true,
  },
  {
    path: "/",
    component: AppMain,
    redirect: "/profile",
    name: "UserProfile",
    meta: {
      icon: "user",
    },
    children: [
      {
        path: "profile",
        component: () => import("@/views/users/View"),
        meta: {
          title: "Profile",
        },
      },
    ],
  },
];

//  Routes that requires permission to  access
export const asyncRoutes = [
  {
    path: "/records",
    component: AppMain,
    redirect: "/records/list",
    name: "RecordManagement",
    meta: {
      title: "Data Records",
      icon: "list",
      permissions: ["view records"],
    },
    children: [
      {
        path: "list",
        component: () => import("@/views/data/DataRecords"),
        name: "DataRecords",
        meta: { title: "Data Records" },
      },
    ],
  },
  DataManagementRoutes,
  MetaManagementRoutes,
  UserManagementRoutes,
  //RoleRoutes,
  {
    path: "*",
    name: "404",
    meta: { title: "404" },
    component: () => import("@/views/error-page/404"),
    hidden: true,
  },
];

const createRouter = () =>
  new VueRouter({
    scrollBehavior: () => ({ y: 0 }),
    base: "",
    routes: constantRoutes,
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
