import Cookies from "js-cookie";

const TokenKey = "TOKEN";

/**
 *
 * @returns
 */
export function getToken() {
  return Cookies.get(TokenKey);
}

/**
 *
 * @param {*} value
 * @returns
 */
export function setToken(value, duration = 0) {
  if (duration <= 0) return Cookies.set(TokenKey, value);
  return Cookies.set(TokenKey, value, { expires: duration });
}

/**
 *
 * @returns
 */
export function removeToken() {
  return Cookies.remove(TokenKey);
}
