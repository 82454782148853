<template>
  <div :class="{ 'has-logo': showLogo }">
    <b-sidebar id="sidebar-1" title="MSDAT DMI" text-variant="white" bg-variant="primary" shadow>
      <div class="px-3 py-2"></div>
      <el-menu
        class="el-menu-vertical-demo"
        :collapse="isCollapse"
        background-color="#003C6F"
        text-color="#FBFBFB"
        router
        :default-active="$route.path"
        :show-timeout="200"
        mode="vertical"
        unique-opened
      >
        <SidebarItem
          v-for="route in visibleRoutes"
          :key="route.path"
          :item="route"
          :base-path="route.path"
        />
      </el-menu>
    </b-sidebar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SidebarItem from "./SidebarItem.vue";
// import Logo from './Logo.vue';

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    SidebarItem,
    // Logo
  },
  computed: {
    ...mapGetters(["sidebar", "permission_routers"]),
    routes() {
      return this.$store.state.permission.allRoutes;
    },
    visibleRoutes() {
      return this.routes.filter((item) => !item.hidden && item.children);
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    isCollapse() {
      return false;
    },
  },
  methods: {},
};
</script>
