import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import app from "./modules/app";
import data from "./modules/data";
import getters from "./getters";
import permission from "./modules/permission";
import settings from "./modules/settings";
import user from "./modules/user";
import users from "./modules/users";

Vue.use(Vuex);

// eslint-disable-next-line no-unused-vars
const dataState = createPersistedState({
  paths: ["data", "user", "users"],
});

const store = new Vuex.Store({
  modules: {
    app,
    permission,
    user,
    settings,
    users,
    data,
  },
  getters,
  plugins: [],
});

export default store;
