import Resource from "./resource";
import request from "../utils/request";

class UserResource extends Resource {
  constructor() {
    super("accounts");
  }
  list() {
    return request.get(`/${this.uri}/users`);
  }
  /**
   *
   * @param {String} email
   * @param {String} firstName
   * @param {String} lastName
   * @param {Array<String>} roles
   * @returns
   */
  invite(email, firstName, lastName, roles) {
    return request.post(`${this.uri}/invite/invite_user/`, {
      email,
      firstName,
      lastName,
      roles,
    });
  }
}

export default new UserResource();
