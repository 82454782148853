/* eslint-disable max-len */

import { asyncRoutes, constantRoutes } from "../../router";

/**
 * Check if it matches the current user right by meta.role
 * @param {String[]} roles
 * @param {String[]} permissions
 * @param route
 */
function canAccess(roles, permissions, route) {
  if (route.meta) {
    let hasRole = true;
    let hasPermission = true;
    if (route.meta.roles || route.meta.permissions) {
      // If it has meta.roles or meta.permissions, accessible = hasRole || permission
      hasRole = false;
      hasPermission = false;
      if (route.meta.roles) {
        hasRole = roles.some((role) => route.meta.roles.includes(role));
      }

      if (route.meta.permissions) {
        hasPermission = permissions.some((permission) =>
          route.meta.permissions.includes(permission)
        );
      }
    }

    return hasRole || hasPermission;
  }

  // If no meta.roles/meta.permissions inputted - the route should be accessible
  return true;
}

/**
 * Find all routes of this role
 * @param routes asyncRoutes
 * @param roles
 */
function filterAsyncRoutes(routes, roles, permissions) {
  const res = [];

  routes.forEach((route) => {
    const tmp = { ...route };
    if (canAccess(roles, permissions, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles, permissions);
      }
      res.push(tmp);
    }
  });

  return res;
}

const defaultState = () => ({
  allRoutes: constantRoutes,
  addedRoutes: [],
  routesGenerated: false,
});

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, defaultState());
  },
  SET_ROUTES: (state, routes) => {
    state.addedRoutes = routes;
    state.allRoutes = constantRoutes.concat(routes);
    state.routesGenerated = true;
  },
};

const actions = {
  reset_state({ commit }) {
    commit("RESET_STATE");
  },
  generateRoutes({ commit }, { roles, permissions }) {
    return new Promise((resolve) => {
      let accessibleRoutes;
      if (roles.includes("admin")) {
        accessibleRoutes = asyncRoutes || [];
      } else {
        accessibleRoutes = filterAsyncRoutes(asyncRoutes, roles, permissions);
      }
      commit("SET_ROUTES", accessibleRoutes);
      resolve(accessibleRoutes);
    });
  },
};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
};
