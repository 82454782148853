import { isExternal } from "@/utils/validate";

export default {
  methods: {
    isExternalLink(routePath) {
      return isExternal(routePath);
    },
    generateTitle(x) {
      return x;
    },
  },
};
